import { gql } from "@apollo/client"
import styled from "@emotion/styled"
import { LinkText, theme } from "@pallet-hq/hegel"
import { FiSmile, FiZap } from "react-icons/fi"
import InfiniteScroll from "react-infinite-scroller"
import { SpaceBetweenVertCenteredRow } from "components/atoms"
import JobItem from "components/JobItem"
import JobItemSkeleton from "components/JobItem/skeletons/JobItem.skeleton"
import EmptyApplyList from "page-components/list/EmptyApplyList"
import useJobPreferences from "state/jobPreferences/useJobPreferences"
import useCandidateUpsellModal from "state/useCandidateUpsellModal"
import JobListSectionHeader from "./JobListSectionHeader"
import { JobList_postconnection } from "__generated__/JobList_postconnection"

const JobListFragment = gql`
    fragment JobList_postconnection on PostTypeConnection {
        pageInfo {
            hasNextPage
            endCursor
        }
        edges {
            node {
                ...JobItem_post
                matchesGlobalPreferences
                jobTypeCategories
            }
        }
    }

    ${JobItem.fragment}
`

export type JobListProps = {
    posts: JobList_postconnection
    hasPreferences: boolean
    canEdit: boolean
    notifyOnUpdate: boolean
    fetchMore: any
    queryName: string
    offset: number
    selected: { name: string; slug: string }
}

const JobList = (props: JobListProps) => {
    const { setJobPreferencesIsOpen } = useJobPreferences()
    const { autoShowModal } = useCandidateUpsellModal()

    const loadMore = () => {
        if (
            !props.posts.pageInfo.hasNextPage ||
            !props.posts.pageInfo.endCursor
        )
            return

        props.fetchMore({
            variables: {
                cursor: props.posts.pageInfo.endCursor,
                offset: props.offset,
            },
        })
    }

    let allJobsShown = false
    return (
        <InfiniteScroll
            loadMore={loadMore}
            hasMore={props.posts.pageInfo.hasNextPage}
            loader={<JobItemSkeleton />}
        >
            {props.hasPreferences && (
                <SectionHeaderDiv>
                    <JobListSectionHeader
                        key="jobs-for-you"
                        icon={FiZap}
                        text={props.selected.name || "For You"}
                    />
                    <LinkText
                        size="small"
                        color={theme.colors.gray600}
                        onClick={() => setJobPreferencesIsOpen(true)}
                    >
                        Filters
                    </LinkText>
                </SectionHeaderDiv>
            )}
            {props.posts.edges[0].node &&
                ((props.selected.slug &&
                    !props.posts.edges[0].node.jobTypeCategories!.includes(
                        props.selected.slug
                    )) ||
                    (!props.selected.slug &&
                        !props.posts.edges[0].node
                            .matchesGlobalPreferences)) && (
                    <EmptyApplyList
                        reason={
                            "There are zero jobs that match your preferences."
                        }
                        showBroaden
                    />
                )}
            {props.posts!.edges!.map(post => {
                if (post.node) {
                    const showAllJobs =
                        !allJobsShown &&
                        (!props.hasPreferences ||
                            (!props.selected.slug &&
                                !post.node.matchesGlobalPreferences) ||
                            (Boolean(props.selected.slug) &&
                                !post.node.jobTypeCategories!.includes(
                                    props.selected.slug
                                )))
                    const jobPost = (
                        <div key={post.node.id}>
                            {showAllJobs && (
                                <SectionHeaderDiv>
                                    <JobListSectionHeader
                                        icon={FiSmile}
                                        text="All Jobs"
                                    />
                                    {/* if the user does not have preferences set */}
                                    {!props.hasPreferences && (
                                        <LinkText
                                            size="small"
                                            color={theme.colors.gray600}
                                            onClick={() =>
                                                setJobPreferencesIsOpen(true)
                                            }
                                        >
                                            Filters
                                        </LinkText>
                                    )}
                                </SectionHeaderDiv>
                            )}
                            <JobItem
                                post={post.node}
                                canEditApplylist={props.canEdit}
                                handleClick={autoShowModal}
                            />
                        </div>
                    )
                    allJobsShown = allJobsShown || showAllJobs
                    return jobPost
                } else return null
            })}
        </InfiniteScroll>
    )
}

const SkeletonLoader = () => (
    <>
        <SectionHeaderDiv>
            <JobListSectionHeader
                key="jobs-for-you"
                icon={FiZap}
                text="For You"
            />
        </SectionHeaderDiv>
        {[...Array(5)].map((_, index) => (
            <JobItemSkeleton key={index} />
        ))}
    </>
)

const SectionHeaderDiv = styled(SpaceBetweenVertCenteredRow)`
    margin-bottom: ${theme.space.small};
`

JobList.skeleton = SkeletonLoader
JobList.fragment = JobListFragment

export default JobList
