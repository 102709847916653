import styled from "@emotion/styled"
import { Pill, theme } from "@pallet-hq/hegel"
import { getApolloInstance } from "apollo/client"
import { TRACK_EVENTS } from "constants/events"
import mixpanel from "mixpanel-browser"
import { useCallback } from "react"
import { ListJobPostsQuery_applylist_categoryTags } from "__generated__/ListJobPostsQuery"
import { LIST_JOB_POSTS_QUERY } from "./queries"
import JobTagSkeleton from "./skeletons/JobTag.skeleton"

export type JobCategoryTagsProps = {
    listSlug: string
    categoryTags: ListJobPostsQuery_applylist_categoryTags[]
    selected: string
    defaultVariables: any
    setSelected: (a: { name: string; slug: string }) => any
}

const JobCategoryTag = ({
    enabled,
    defaultVariables,
    listSlug,
    setSelected,
    category,
}: {
    enabled: boolean
    defaultVariables: any
    setSelected: (a: { name: string; slug: string }) => void
    listSlug: string
    category: ListJobPostsQuery_applylist_categoryTags
}) => {
    const client = getApolloInstance()

    const loadCategory = useCallback(async () => {
        return client.query({
            query: LIST_JOB_POSTS_QUERY,
            variables: {
                ...defaultVariables,
                selectedCategory: category.slug,
            },
        })
    }, [category.slug, client, defaultVariables])

    return (
        <div
            onMouseOver={() => {
                loadCategory()
            }}
        >
            <CustomPill
                themed={enabled}
                color="gray900"
                onClick={() => {
                    mixpanel.track(TRACK_EVENTS.JOB_CATEGORY_SELECTED, {
                        applylist_slug: listSlug,
                        job_category_slug: category.slug,
                        job_category_name: category.name,
                    })
                    setSelected({ slug: category.slug, name: category.name })
                }}
            >
                {category.name} ({category.count})
            </CustomPill>
        </div>
    )
}

const JobCategoryTags = (props: JobCategoryTagsProps) => {
    const allEnabled = props.selected === ""
    return (
        <Wrapper>
            <TagList>
                <div>
                    <CustomPill
                        themed={allEnabled}
                        onClick={() =>
                            props.setSelected({ name: "", slug: "" })
                        }
                    >
                        Your Preferences
                    </CustomPill>
                </div>
                {props.categoryTags.map(category => {
                    const enabled = props.selected === category.slug
                    return (
                        <JobCategoryTag
                            defaultVariables={props.defaultVariables}
                            setSelected={props.setSelected}
                            listSlug={props.listSlug}
                            key={category.slug}
                            enabled={enabled}
                            category={category}
                        />
                    )
                })}
            </TagList>
        </Wrapper>
    )
}

const SkeletonLoader = () => {
    const sizes = [137, 261, 313, 130, 140, 110, 194, 90]

    return (
        <Wrapper>
            <TagList>
                {sizes.map((size, index) => (
                    <div key={index}>
                        <JobTagSkeleton width={size} />
                    </div>
                ))}
            </TagList>
        </Wrapper>
    )
}

JobCategoryTags.skeleton = SkeletonLoader

export default JobCategoryTags

const Wrapper = styled.div`
    margin-left: calc(${theme.space.xxsmall} * -1);
    padding: ${theme.space.xsmall} 0 ${theme.space.small};
    margin-top: ${theme.space.xsmall};
`

const CustomPill = styled(Pill)`
    cursor: pointer;
`

const TagList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.space.xxsmall};
`
