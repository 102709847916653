import { useQuery } from "@apollo/client"
import PermissionDenied from "components/PermissionDenied"
import EmptyApplyList from "page-components/list/EmptyApplyList"
import React, { useState } from "react"
import useJobPreferences from "state/jobPreferences/useJobPreferences"
import { useHasMounted } from "utils/hooks"
import { ListJobPostsQuery } from "__generated__/ListJobPostsQuery"
import JobCategoryTags from "./JobCategoryTags"
import JobList from "./JobList"
import { LIST_JOB_POSTS_QUERY } from "./queries"

const LIST_JOB_POST_OFFSET = 15

type ListJobPostsProps = {
    slug: string
}

const ListJobPosts = ({ slug }: ListJobPostsProps) => {
    const {
        experiences,
        jobTypes,
        hasPreferences,
        locations2,
        isOpenToRemote,
    } = useJobPreferences()
    const [selected, setSelected] = useState<{ name: string; slug: string }>({
        name: "",
        slug: "",
    })
    const isMounted = useHasMounted()
    const defaultVariables = {
        slug,
        applylist: slug,
        offset: LIST_JOB_POST_OFFSET,
        experiences,
        locations2,
        isOpenToRemote,
        jobTypes,
        selectedCategory: selected.slug,
        ignoreJobTypes: !!selected.slug,
    }

    const { loading, data, fetchMore, error, previousData } =
        useQuery<ListJobPostsQuery>(LIST_JOB_POSTS_QUERY, {
            variables: defaultVariables,
            skip: !isMounted,
        })

    const prevData = data ?? previousData
    const applylist = loading ? prevData?.applylist! : data?.applylist!

    // show loading when no previous data is available
    if (error) return <PermissionDenied />
    if (!applylist)
        return (
            <>
                <JobCategoryTags.skeleton />
                <JobList.skeleton />
            </>
        )

    return applylist?.postCount === 0 ? (
        <EmptyApplyList reason="You're early to the party! There are 0 posts in this list." />
    ) : (
        <>
            <JobCategoryTags
                listSlug={slug}
                selected={selected.slug}
                defaultVariables={defaultVariables}
                setSelected={setSelected}
                categoryTags={applylist.categoryTags}
            />
            <JobList
                selected={selected}
                posts={applylist.posts!}
                queryName="applylist"
                fetchMore={fetchMore}
                offset={LIST_JOB_POST_OFFSET}
                hasPreferences={hasPreferences || !!selected.slug}
                canEdit={applylist.canEdit!}
                notifyOnUpdate={!!applylist.notifyOnUpdate}
            />
        </>
    )
}

export default ListJobPosts
