import { gql } from "@apollo/client"
import JobList from "./JobList"

export const LIST_JOB_POSTS_QUERY = gql`
    query ListJobPostsQuery(
        $slug: String!
        $applylist: String
        $cursor: String
        $offset: Int!
        $jobTypes: [String]
        $locations2: [LocationInputType!]
        $isOpenToRemote: Boolean
        $experiences: [String]
        $selectedCategory: String
        $ignoreJobTypes: Boolean!
    ) {
        applylist(slug: $slug) {
            ... on ApplylistType {
                id
                slug
                postCount
                canEdit
                notifyOnUpdate
                categoryTags {
                    name
                    slug
                    count
                }
                posts(
                    first: $offset
                    after: $cursor
                    jobTypes: $jobTypes
                    locations2: $locations2
                    isOpenToRemote: $isOpenToRemote
                    experiences: $experiences
                    selectedCategory: $selectedCategory
                    ignoreJobTypes: $ignoreJobTypes
                ) @connection(key: $slug) {
                    ...JobList_postconnection
                }
            }
        }
    }
    ${JobList.fragment}
`
