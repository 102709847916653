import { theme } from "@pallet-hq/hegel"
import ContentLoader from "react-content-loader"

type JobTagSkeletonProps = {
    width?: number
}

const JobTagSkeleton = (props: JobTagSkeletonProps) => (
    <ContentLoader
        speed={2}
        width={182}
        height={32}
        viewBox={`0 0 ${props.width || 182} 32`}
        backgroundColor={theme.colors.gray200}
        foregroundColor={theme.colors.gray400}
        stroke="none"
        display="block"
        {...props}
    >
        <rect
            x="0"
            y="0"
            rx="16"
            ry="16"
            width={props.width || 182}
            height="32"
        />
    </ContentLoader>
)

export default JobTagSkeleton
