import styled from "@emotion/styled"
import { mq, Text, theme } from "@pallet-hq/hegel"
import { ComponentType } from "react"
import { VerticallyCenteredRow } from "components/atoms"

export type JobListSectionHeaderProps = {
    text: string
    icon: ComponentType<any>
}

const JobListSectionHeader = (props: JobListSectionHeaderProps) => {
    const Icon = props.icon

    return (
        <JobListSectionHeaderDiv>
            <Icon size={24} />
            <Text variant="uiSmall600">{props.text}</Text>
        </JobListSectionHeaderDiv>
    )
}

const JobListSectionHeaderDiv = styled(VerticallyCenteredRow)`
    ${mq({
        padding: [`${theme.space.small} 0`, `${theme.space.xsmall} 0`],
    })};
    box-sizing: border-box;
    gap: ${theme.space.small};
`

export default JobListSectionHeader
